import * as React from 'react'
import SmallBanner from '../components/SmallBanner'
import Container from '../components/Container'
import styled from 'styled-components'
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { breakpoint, colors } from '../styles/variables'
import { Link } from 'gatsby'


const Wrapper = styled(Container)`
  min-height: 100vh;
  margin-top: 2rem;
  margin-bottom: 3rem;
  max-width: 700px;
  padding: 0 1rem 2rem;

  @media ${breakpoint.sm} {
    margin-top: 3rem;
  }

  @media ${breakpoint.md} {
    margin-top: 4.5rem;
    margin-bottom: 5rem;
  }

  @media ${breakpoint.lg} {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
`

const Title = styled.h2 `
    margin-top: 0;
    text-transform: none;
    margin-bottom: 0.5em;
    font-size: 1.75rem;
`

const StyledFigure = styled.figure `
    margin: 1.5rem 0 2rem;
    display: grid;
    justify-content: center;

   /*  img {
         max-height: 440px;
    }

    ${({doubleImage}) => `${doubleImage && `
        @media ${breakpoint.sm} {
            grid-template-rows: 440px auto;
        }
    `}
    `} */


    @media ${breakpoint.md} {
        margin: 2rem 0 2.5rem;
    }

   /*  img {
        max-height: 440px;
        width: auto;
    } */
`

const StyledFigcaption = styled.figcaption `
    font-size: 0.875rem;
    color: gray;
    margin-top: 0.5em;
    text-align: right;
`

const BackLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primary};
  margin-bottom: 2.5rem;
  display: inline-block;
  font-size: 0.875rem;
  transition: color 0.3s ease;

  @media ${breakpoint.lg} {
    margin-bottom: 4rem;
  }

  &:hover {
    color: ${colors.orangeHover};
  }

  .icon {
    margin-right: 0.5rem;
  }
`

const Date = styled.p`
    color: gray;
    margin: 0;
    font-size: 0.875rem;
`

const BlogList = styled.ol `
     padding: 0;
     list-style: none;
    counter-reset: my-awesome-counter;

    h3 {
        font-size: 1rem;
        padding-left: 1.25em;
        margin-bottom: 0.5em;
        font-weight: 700;
    }
    p {
        display: inline;
    }

    li {
        margin: 1.5rem 0;
        line-height: 1.5;
        counter-increment: my-awesome-counter2;
        position: relative;

        &::before {
            content: counter(my-awesome-counter2) "." ;
            font-weight: 700;
            position: absolute;
        }

        ul li::before {
            content: normal;
        }
    }
`
const DoubleImage = styled.div `
    margin: 1.5rem 0 2.5rem;

    @media ${breakpoint.sm} {
        display: grid;
     gap: 0.5rem;
        grid-template-columns: repeat(2, 1fr);
    }
`

const Author = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 0.875rem;
  opacity: 0.75;
`

const Info = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem 0 0.5rem;
  margin-bottom: 2rem;
`

const SectionTitle = styled.h3`
    padding-top: 1em;
`

const BlogpostPage = ({data, title}) => {

    return (
       <>
        <SmallBanner title="Blog"/>
        <Wrapper>
            <BackLink to="/blog">
                <span className="icon icon-arrow-left"></span>
                Zpět na výpis článků
            </BackLink>
            <Title>Camino - jak jsem poznala Charlie</Title>
            <Info>
                <Author>Julia</Author>
                <Date>17. 5. 2023</Date>
            </Info>
            <SectionTitle>Den 7 - Casa Alternativo</SectionTitle>
            <p><a href="https://www.facebook.com/CasaAlternativo/">Casa Alternativo</a> - to byl cíl můj, Charlie a Vereny. Tak nějak večer, když jsme upíjely několikátou
                    skleničku výborného portugalského vína, mi holky o tomto Albergue začaly vyprávět. Moc se mi to
                    zalíbilo a představa, že po tak náročném večeru a těch 20-25 km denně, co jsem posledních pár
                    dní absolvovala, půjdu pouze 17, byla velmi lákavá.</p>
            <p>Ráno jsem tedy vyrazila okolo 8, což nebyl zrovna čas toho pravého poutníka, ale nevadí - čeká
                mě dnes pohoda. Dneska to byl ten den, kdy jsme přecházely Portugalsko - Španělskou hranici.
                Po cestě jsme se tak různě potkávaly - já, Charlie a Verena. Míjely jsme se, prohodily vždy pár
                slov a buď pokračovaly chvíli spolu nebo každá sama, či ve dvojicích. To je to kouzlo poutě - i
                když jdete sami, nikdy sami nejste. A pokud sám/a být chcete, můžete a nikomu nemusíte nic
                vysvětlovat.</p>
                <DoubleImage>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-1.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-2.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                </DoubleImage>
                <p>Najednou za hranicemi Portugalska, když jsem přešla most, padla na mě hrozně zvláštní nálada -
radost, smutek, hrdost (sama na sebe), úleva... Smutek - blíží se konec, radost - mám toho za
sebou už tolik, hrdost - překonala jsem sama sebe, úleva - už mě nic nebolí a budu zase mít
peníze. Najednou jsem objevila bezkontaktní bankomaty, kde stačí přiložit kartu v telefonu a
vybrat si.</p>
<p>Ve města Tui jsem opět narazila na Charlie. Sedly jsme si spolu v kavárně na kafe a cigaretu. Pak
jsme vyrazily spolu a vlastně jsme pak šly spolu ten den pořád. Když jsme chtěly mluvit - mluvily
jsme, když jsem chtěly mlčet - mlčely jsme.</p>
<p>Různě jsme si něco vyprávěly, poznávaly se a smály. Někde posledních 5 km před Casa
Alternativo, jsme narazily na klučinu z Polska. Nejdřív nás předběhl, pak na nás čekal. Chvíli jsme
se bavili, vyprávěli si kdo, odkud pochází. Po půl hodině se chlapec z Polska rozhodl, že
potřebuje zavolat někomu domů. Pokračovaly jsme tedy s Charlie dál samy a klučina šel cca 200
m za námi a vlastně zbytek cesty pořád jen někomu volal. Dozvěděly jsme se, že měl na cestu z
Porta do Santiaga pouze týden. Šílené :-)</p>
<p>Do Casa Alternativo jsme dorazily chvíli po 2 odpoledne. Hned na terase na nás už čekala Verena,
která tam byla cca o půl hodiny dříve. Ubytovaly jsme se, seznámily se s Driesem (majitel) a Ottem
(jeho úžasný psí parťák).</p>
                <DoubleImage>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-3.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-4.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                </DoubleImage>
        <p>Toto místo je takové menší soukromé Albergue - je dražší než ostatní, ale naprosto oprávněně (za
mě :)). Dries totiž nabízí krásně zázemí, velký dvůr se spoustou místa na relaxaci a odpočinek,
úžasnou domácí tříchodovou večeři, výborné víno (případně dobře chlazené pivo), úžasnou
muziku a skvělou psí společnost. K tomu navíc moc příjemný a obdivuhodný příběh toho, jak
Casa Alternativo vznikla. A ráno? Ráno nás čekal nezapomenutelný budíček a velmi chutná
snídaně včetně kafe :-)</p>
                <DoubleImage>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-5.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-6.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                </DoubleImage>
                <p>Večer jsme strávili u hry UNO, piva a povídání si o všech možných věcech, které nás trápí a dělají
nám radost.</p>
<SectionTitle>Den 8 - Stačí si přát</SectionTitle>
<p>Ráno jsme vyrazily opět každá zvlášť a po pár prvních kilometrech jsme zase šly spolu s Charlie.
Již večer jsme dostaly od Driese radu, že ta delší cesta do O Porrino je mnohem hezčí, tudíž jsme
měly jasnou volbu, kudy vyrazíme. A musím říct, že to byl opravdu moc hezký úsek.</p>
<DoubleImage>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-7.jpg" alt=""></StaticImage>
                    </StyledFigure>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-8.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                </DoubleImage>
                <p>S Charlie jsme si velmi padly do oka. Bylo to celé takové přirozené - vtipy, rozhovory a chuť na
cigaretu nebo kafe po cestě :-).</p>
<StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-9.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                    <p>Ten den nebylo úplně dobré počasí, ale cesta nám hrozně utekla. Různě jsme si i vymýšlely, kde
by se nám líbilo Albergue, snily jsme o tom, jak by vypadalo.</p>
<p>Poslední úsek cesty byl lehce náročný - prudké kopce dolů. Během posledních pár kilometrů jsem
říkala Charlie, že <b>by se mi líbilo zažít po cestě nějaký španělský festival</b>. Nějakou muziku,
oslavu, tance a tak dále.</p>
<p>Během dne jsme si taky řekly, že další ubytování v cíli si najdeme spolu a opět tam zůstaneme
spolu. A tak jsme si našly pomocí bookingu soukromé Albergue (hostel) v centru města.</p>
<p>Po příchodu na Albergue jsme zjistily, že jsme první. Jako první vždy byla sprcha. Následně
dorazila majitelka a seznámila nás se samotným Albergue a navíc! <b>
    Oznámila nám, že máme
    velké štěstí! V Redondele se ten den (vlastně víkend) konal Festival!
</b> V tu chvíli se na mě Charlie jen podívala s frází “Julie, no tak, jak jsi to věděla?!”. Nevěděla, jen jsem si to přála :-)</p>
<DoubleImage>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-10.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-11.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                </DoubleImage>
                <p>Festival se jmenoval <a href="https://turismoriasbaixas.com/en/descubre/fiestas/fiesta-de-la-coca"><b>Festa da Coca</b></a> - v minulosti mladí námořníci z Redondely porazili draka a od
té doby se jejich vítězství každoročně slaví živým vrcholným tancem. Kromě těchto mladých mužů
jsou součástí tance i mladé okřídlené dívky oděné v bílém, představující panny. Tyto dívky tančí na
ramenou burry, žen, které fungují jako jejich matky.</p>
<p>Tento festival doprovází různé koncerty a vystoupení, a co víc - celé ulice centra (včetně té, nad
kterou jsme měly balkón) byly vyzdobené na zemi okvětními lístky do různých vzorů. Pracovali na
tom všichni - děti, puberťáci, dospělí a staří. Celé město.</p>
<p>Další poutníci dorazili hned po nás, byl to pár z USA. Asi hodinu jsme si povídali a pak jsme
vyrazily na jídlo do města a vychutnat si atmosféru oslav.</p>
<p>Užily jsme si na náměstí pizzu z krabice dokud nepřišla ta největší bouře, kterou jsme zatím na
cestě zažily. Pak jsme si dopřály alko drink bez alkoholu v baru než přestalo pršet, a pak jsme
dobíhaly v žabkách na koncert místních Queens!</p>
<StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-12.jpeg" alt=""></StaticImage>
                    </StyledFigure>
<p>Večer byl úžasný, ještě do 2 do rána jsme sledovaly z balkónu, jak celé město dál pokračuje ve
zdobení ulic.</p>
<SectionTitle>Den 9 - nebudeme pít, dokud se nenajíme</SectionTitle>
<p>Ráno jsme vstávaly o něco později a z Albergue jsme odcházely jako poslední. Nevadí, večer jsme
si užily a vůbec nám to nevadilo.</p>
<DoubleImage>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-13.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-14.jpg" alt=""></StaticImage>
                    </StyledFigure>
                </DoubleImage>
                <p>Po cestě jsme opět potkaly několik lidí z předchozích dnů, jak už to na Caminu bývá. Je to vlastně
příjemné - mě osobně to vždy velmi potěšilo. Úsměv, vyprávění o tom, co kdo zažil, co ho bolí a
trápí, co se mu líbilo - jako kdybyste byli všichni najednou přátele.</p>
<p>Počasí bylo trošku chladnější než předchozí dny - bylo zataženo a teploty okolo 20 stupňů. Před
námi několik kopců, cest přes lesy a pak zase přes vesničky a vesnické vinice. Dorazily jsme do
Pontevedra. Zde bývá často první nebo druhá zastávka poutníků, kteří vyráží na svou pouť z města Vigo (je to kratší varianta pouti, která splňuje podmínku pro získání diplomu poutníka - 100
km). Na Albergue jsme se seznámily s párem z Anglie, kteří právě z Viga vyrazili.</p>
<DoubleImage>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-15.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-16.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                </DoubleImage>
                <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-17.jpeg" alt=""></StaticImage>
                    </StyledFigure>
<p>Najednou jsme měly s Charlie rituál - malé pivo na ubytování ihned, jak jsme dorazily. Pak
následovala sprcha a cesta do města za nějakým jídlem. Našla jsem v Google mapách super
místo na Tapas, které zrovna otevíralo.</p>
<p>Po příchodu jsme si řekly, že si nedáme nic alkoholického (i přesto, že už jsme měly jedno malé
pivo). Byly jsme v restauraci první a Charlie dostala chuť na kafe.</p>
<p>
- “Do you have a coffee?”<br />
- “Yes, sure - coffee liquor”<br />
- “Ok, for me one please” ,<br />
- “For me the same”<br />
- “Ok, thank you”
</p>
<p>Poté se na mě Charlie jen podívala a nechápavě se zeptala:</p>
<p>
- “Coffee is liquid anyway?!”<br />
- “Yes, but he said coffee liquor not liquid!” :-)
</p>
<p>Tím padla naše dohoda.</p>
<p>Poté, co nám číšník donesl dva panáky naprosto skvělého kávového likéru, jsme si objednaly
několik Tapas a nějaké lokální víno.</p>
<DoubleImage>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-18.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-19.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                </DoubleImage>
                <p>A o několik hodin později a dvou láhvích vína, jsme se vracely zpět na Albergue prázdným
městem. Před Albergue jsme si ještě do 2 do rána povídaly - vyprávěly jsme si různé životní
příběhy, ty příjemné, i ty méně příjemné. Šlo to tak nějak samo, jako kdybychom se znaly několik
let. Možná to bylo vínem, možná tou poutí a možná jenom tím, že jsme si hodně lidsky sedly.
Rozhodně to byl jeden z nejlepších večerů.</p>
<StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-20.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                    <SectionTitle>Den 10 - První poutnice z Ukrajiny</SectionTitle>
                    <p>Ráno jsme si trošku přispaly. Po zastávce na snídani a kafe, jsme narazily na pána s kočárkem, ve
kterém byl roztomilý pejsek - ano, pes! :-D hlavou nám prolítlo, co bychom daly za to sedět v tom
kočárku místo něj - “Až do Santiaga, prosím”.</p>
<DoubleImage>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-21.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-22.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                </DoubleImage>

<p>Po cestě jsme pokračovaly v našich konverzacích z večera. Občas jsme si prostě jen zpívaly,
někdy jsme si i zatančily, jindy zas jen mlčely. To je to kouzlo - nemusíme přeci pořád jenom
mluvit. A považuji svým způsobem za umění, pokud spolu dva lidé dokážou mlčet.</p>
<p>Dnešní část cesty byla ve znamení nádherných lesů, jak z pohádky a vinic. Po celou cestu bylo
příjemných 20 stupňů.</p>

<DoubleImage>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-23.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-24.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                </DoubleImage>
    <p>Během druhé části cesty se nám v hlavě zrodil geniální nápad - pojďme si nechat udělat nějaké
tetování po dokončení pouti. Něco, co nám bude oběma připomínat těchto naprosto dokonalých
12 dní. Dohodly jsme se, že ihned po dokončení navštívíme nějaké tetovací studio a půjdeme do
toho spolu.</p>
<p>Zároveň jsem se zeptala Charlie, zda si třeba nepřeje pokračovat v pouti dál sama a řekla mi jen
to, že si to už neumí představit. To mě neskutečně dojalo a musím říct, že jsem měla stejný pocit.</p>
<p>Našim cílem bylo městečko Caldas de Reis. Ubytovaly jsme se v hostelu (soukromé Albergue) a
pro sprše jsme si vyrazily tradičně na vychlazenou Estrella Galicia.</p>
<p>Po návratu do Albergue jsme potkaly poutnici, která měla pro mě zvláštní přízvuk. Hned jsem se
zeptala odkud je a ten přízvuk najednou dával smysl, poutnice byla z Ukrajiny. První člověk z
Ukrajiny, kterého jsem na celé pouti potkala. Larysa bylo původem z města Luck, po vypuknutí
války se odstěhovala do Polska za dcerou, která tam zrovna studovala.</p>
<StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-25.jpeg" alt=""></StaticImage>
                    </StyledFigure>
        <p>Zbytek večera jsme strávily všechny 3 společně. Zašly jsme si na večeři, na místní termální lázeň a
následně jsme si ještě prošly městečko.</p>
<p>Další velmi příjemná společnost a další naprosto skvělý den.</p>
<SectionTitle>Den 11 - Oběd smrti</SectionTitle>
<p>Ráno jsme našly na nočním stolku krásný vzkaz od naši spolubydlící z Ukrajiny. Bylo to moc
hezké potkat někoho ze své rodné země.</p>
<p>V první polovině cesty jsme si s Charlie daly sázku - pokud potkáme dneska pár z USA, slečnu z
Kanady a pár z Anglie, dlužím Charlie pivo, pokud nepotkáme, dluží pivo ona mě. Uvidíme :-)</p>
<p>Musím říct, že netrvalo dlouho a hned po pár kilometrech jsme potkaly pár z Anglie, po dalších
kilometrech holčinu z Kanady, tuším, že se jmenovala Annie.</p>
<p>Cesta utíkala až nečekaně rychle. Narazily jsme na místní školku, kde bylo spousta přání pro
poutníky v ukrajinštině, zkusily jsme ukrást pomeranč ze stromů podél cesty a během posledních
5 kilometrů jsme už dostaly hlad.</p>
<p>V mapě jsem zbystřila nějaký podnik, který byl označen jako restaurace. Super!</p>
<p>Ihned v prvních vteřinách jsme zjistily, že dá se to nazvat všelijak, ale rozhodně ne restaurace.
Dvůr se 3 stoly a plný všeho možného bordelu. Různé nářadí, krámy a starý nábytek. U jednoho
ze stolu seděl pán, který si zrovna dopřával oběd. Slušně jsme pozdravily a zeptaly se, zda tam
vaří, že máme hlad. Pán nám velmi ochotně ukázal na svůj talíř a zeptal se, zda si to dáme taky. <br />
- “No jasný, proč ne?!”</p>
<p>Po usazení ke stolu každá s nás dostala talíř s něčím, co nevypadalo úplně vábně - dušené
brambory, mrkev, chobotnice, houby a fazole. Jen jsme se na sebe podívaly a já jen pronesla
“Hele, on to přeci jedl taky, ne? Co se nám může stát!” :-D</p>

<DoubleImage>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-27.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-26.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                </DoubleImage>

<p>Pustily jsme se do toho a vlastně to nebylo vůbec špatné. Chutnalo to lépe, než vypadalo.</p>
<p>Zbývalo mi asi poslední 4 sousta, ale byla jsem už plná. Odstavila jsem talíř bokem. Když náš
hostitel přišel ke stolu, zeptal se mě, zda to chci zabalit se sebou, nebo to dojím. Slušně jsem
poděkovala a řekla, že už bohužel nemůžu, jsem plná. Podíval se na mě a šel někam to kůlny.
Vrátil se po 2 minutách se sekyrkou v ruce.
“Proboha, jestli se nevrátím domů, řekněte mé rodině, že jsem je měla ráda!”</p>
<p>Důrazně mi řekl, že každý poutník musí dojíst své jídlo, jinak mu tam musí nechat prst - “No tak to
je můj konec”, řekla jsem si. Znáte ten pocit, když už nemůžete a jídlo se vám úplně vrací? :-D Tak
takhle to mám já, když už nemůžu.</p>
<p>Nicméně hostitel někam poodešel a Charlie mi statečně zachránila život a zbytek dojedla :-D.
Poté, co pán odklidil prázdné talíře, tak se najednou proměnil v úplně jiného člověka. Během toho
zároveň dorazil další pár poutníků a pán nám začal vyprávět, co musíme udělat na posledním
úseku poutě než dorazíme do Santiaga. Byl vtipný, milý a hrozně nápomocný. Nechápu :-D</p>
<StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-28.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                    <p>Každopádně jsme se zpětně dívaly na recenzi jeho podniku a musím říct, že to bylo buď hodně
dobré, nebo hodně blbé. Záleželo asi na tom, kdo a jak pochopil jeho styl humoru. My jsme to
vyhodnotili jako vcelku zajímavý a vtipný zážitek.</p>
<p>Město Padron bylo na dohled a my jsme již potkaly větší část lidí z naši sázky. Po příchodu na
Albergue jsme zamířily do místnosti s pračkou a sušičkou, abychom mohly domů letět v něčem,
co zrovna nebude smrdět a hádejte, kdo si nechal ponožky v sušičce? Ano pár z USA, poslední
lidí ze sázky, kteří byli ubytování na stejném Albergue a chyběli k tomu, aby Charlie vyhrála. Bingo!
:-)</p>
<p>Poté, co jsme vyrazily do města, tak bonus pro Charlie bylo to, že jsme potkaly Verenu - ano,
Verena, ta která byla u našeho prvního setkání s Charlie. Tyhle náhody miluji! :-) Daly jsme si
společně pivo a já pak s Charlie vyrazila na večeři do místní Pulperia Rial, která se specializovala na přípravu chobotnic. Musím říct, že miluji mořské plody, ale chobotnice byla pro mě vždy taková méně oblíbená, až do této chvíle - lepší chobotnici jsem neměla.</p>
<DoubleImage>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-29.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-30.jpg" alt=""></StaticImage>
                    </StyledFigure>
                </DoubleImage>

<p>Během večeře ve slepé uličce, kde se restaurace nacházela, si před nám rozbalil své hudební
nástroje a mikrofon jeden poněkud postarší hudebník. Charlie se na mě tenkrát jen podívala a
zeptala, jak jsem to věděla? Samozřejmě jsem řekla, že jsem to objednala. :-D Od té chvíli mi
Charlie říká, že mám čich na dobrá místa, a že se mnou klidně na konec světa :-D</p>
<p>Náš poslední večer před Santiagem byl naprosto dokonalý. Opravdu - ta všechna setkání, ten
zvláštně zajímavý oběd, setkání s Verenou, večeře a nakonec i ta hudba před restaurací... Prostě
takové to, kdy si řeknete, že nechcete, aby něco končilo! A takhle jsem to měla já, snažila jsem se
jen soustředit na ten moment a vychutnat si ho!</p>
<p>Večer jsme opět zakončily dlouhým povídáním před Albergue :)</p>
<SectionTitle>Den 12 - A co dál?</SectionTitle>
<p>Ráno jsme se probudily do deště. Vyrážely jsme v 7 ráno a čekalo nás posledních cca 23 km.
Oblékly jsme si to nejteplejší oblečení, co jsme měly, pláštěnky, zastavily se na místním trhu pro
nějakou svačinu a vyrazily.</p>
<StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-31.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                    <p>Ačkoliv pršelo opravdu dost, tak Charlie zachovala svou pozitivní náladu a sdělila mi, že až
budeme v Santiagu před katedrálou, bude sluníčko, prý si tím je jistá. No, moc tomu nedávám, ale
naděje umírá poslední, ne?! :-)</p>
<StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-32.jpeg" alt=""></StaticImage>
                    </StyledFigure>
<p>Bylo to opravdu takové depresivní ráno, tak jsme si ho po cestě zpříjemňovaly muzikou ve stylu
80’s. Po cestě jsme opět potkaly několik zajímavých poutníků. Jeden z nich během cesty natáčel
podcast a ptal se lidí, co je přimělo k tomu vyrazit na pouť do Santiaga. Potkaly jsme i pár
známých tváří z předchozích dnů.</p>
<p>Celá cesta byla taková zvláštní, myslím, že nám oběma docházelo, že už to končí.</p>
<p>Na posledním úseku bylo vcelku hodně lidí, pořád jsme se proplétaly mezi skupinkami studentů.
Pravděpodobně byli na školním výletě.</p>
<p>Asi 2 km před cílem jsme se s Charlie zastavily na něco k jídlu a jednu naši malou Estrellu Galicii a
přečkaly tak zároveň i další přeháňku. Následně jsme pokračovaly dál, posledních pár metrů jsme
se prodíraly davy turistů a jiných poutníků.</p>
<StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-33.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                    <p>Bylo kolem 15:00 a nejednou jsme tam stály. Jsme v cíli, jsme v Santiagu, zvládly jsme to a svítilo
sluníčko! Ano! Zaplavilo mě hrozně moc různých pocitů - radost, hrdost, štěstí, smutek... Sundaly
jsme si batohy, lehly si na zem, držely se za ruce a brečely :-D Tak nějak to na nás celé spadlo a
zároveň nám došlo, že po intenzivním týdnu, kdy jsme spolu trávili každou minutu, už půjdeme
každá po svém...</p>
<DoubleImage>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-34.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-35.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                </DoubleImage>
<p>Jedna z dalších neskutečných věcí byla, že mezi těmi stovky poutníků na náměstí před
katedrálou, jsem najednou opět zahlédla Verenu! Rozeběhla jsem se pro ni a pak jsme tam seděly
všechny 3 a dávaly si spolu pomeranče a říkaly si, jaké to bylo...</p>
<StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-36.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                    <p>Večer jsme si dohodly sraz, že si dáme takové Reunion :) Zašly jsme si na večeři a pak jsme to
zakončily v místním malém baru, kde jsme byly samy, u písniček z 80’s a piva.</p>
<p>Celé to do sebe tak zapadlo.</p>
<SectionTitle>Den 13 - Suvenýr</SectionTitle>
<p>Dopoledne jsme se s Charlie sešly na tetování. Měly jsme naprosto jasno, co chceme -
svatojakubskou mušli na kotník. Celé to proběhlo velmi rychle a vcelku bezbolestně. Pak jsem si
vyzvedla věci v ubytování, kde zároveň byl i můj pas, na který jsem se mohla vrátit domů.</p>
<StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-37.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                    <p>Charlie mi nabídla, že mě doprovodí na letiště. Letěla jsem přímo ze Santiaga přes Basilej do
Prahy s EasyJet. Celá cesta na letiště a ta poslední hodina byly velmi zvláštní. Ani jedná z nás
nechtěla moc mluvit o tom, co bude dál, co doma atd. Spíš jsme se na sebe často podívaly a
každá z nás měla na krajíčku a bylo nám jasné, že je lepší neříkat nic... :)</p>
<p>Charlie mi nabídla, že mě doprovodí na letiště. Letěla jsem přímo ze Santiaga přes Basilej do
Prahy s EasyJet. Celá cesta na letiště a ta poslední hodina byly velmi zvláštní. Ani jedná z nás
nechtěla moc mluvit o tom, co bude dál, co doma atd. Spíš jsme se na sebe často podívaly a
každá z nás měla na krajíčku a bylo nám jasné, že je lepší neříkat nic... :)</p>
<StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-38.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                    <p>Jen, co jsem prošla kontrolou, zaplavil mě hrozný smutek, stesk a splín. Řvala jsem, jako malá a
vydrželo mi to celou cestu až do Prahy. Dala jsem prostě volný průběh všem svým emocím.</p>
<p>Jela jsem tam, abych byla sama, neměla jsem žádné očekávání ale našla jsem tam mnohem víc.
Našla jsem Charlie, které je skvělá - empatická, chytrá, krásná, inteligentní, vtipná a prostě jsme si
padly do oka. Našla jsem tam hrozně hezké přátelství, které přetrvává.</p>
<p>Je květen 2023 a s Charlie během toho roku jsme se viděly už 3x. Byla jsem za ní v Berlíně, když
slavila narozeniny, pak ona navštívila mě 2x v Praze a strávily jsme spolu příchod roku 2023 a za 2
týdny nás čeká společná dovolená v Rize.</p>
<p><b>A pokud Vás zajímá, jestli půjdu zas do Santiaga? Ano! A ne jednou :)</b></p>

<DoubleImage>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-39.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/camino2/camino2-40.jpeg" alt=""></StaticImage>
                    </StyledFigure>
                </DoubleImage>


        </Wrapper>
       </>
    )
  }

  export default BlogpostPage
